:root {
  --atlas-font-family: 'Noto Sans', sans-serif;
  /** primary **/
  --atlas-color-primary: #EF6C00;
  --atlas-color-primary-rgb: 255, 159, 10;
  --atlas-color-primary-contrast: #fff;
  --atlas-color-primary-contrast-rgb: 255, 255, 255;
  --atlas-color-primary-shade: #cb6007;
  --atlas-color-primary-tint: #FB8C00;

  /** secondary **/
  --atlas-color-secondary: #718096;
  --atlas-color-secondary-rgb: 113, 128, 150;
  --atlas-color-secondary-contrast: #ffffff;
  --atlas-color-secondary-contrast-rgb: 255, 255, 255;
  --atlas-color-secondary-shade: #4A5568;
  --atlas-color-secondary-tint: #A0AEC0;

  /** tertiary **/
  --atlas-color-tertiary: #5260ff;
  --atlas-color-tertiary-rgb: 82, 96, 255;
  --atlas-color-tertiary-contrast: #ffffff;
  --atlas-color-tertiary-contrast-rgb: 255, 255, 255;
  --atlas-color-tertiary-shade: #4854e0;
  --atlas-color-tertiary-tint: #6370ff;

  /** success **/
  --atlas-color-success: #00BA88;
  --atlas-color-success-rgb: 45, 211, 111;
  --atlas-color-success-contrast: #ffffff;
  --atlas-color-success-contrast-rgb: 255, 255, 255;
  --atlas-color-success-shade: #00a378;
  --atlas-color-success-tint: #07d79f;
  --atlas-color-success-light: #F2FFFB;

  /** warning **/
  --atlas-color-warning: #ffc409;
  --atlas-color-warning-rgb: 255, 196, 9;
  --atlas-color-warning-contrast: #000000;
  --atlas-color-warning-contrast-rgb: 0, 0, 0;
  --atlas-color-warning-shade: #e0ac08;
  --atlas-color-warning-tint: #ffca22;

  /** danger **/
  --atlas-color-danger: #CB1A1A;
  --atlas-color-danger-rgb: 235, 68, 90;
  --atlas-color-danger-contrast: #ffffff;
  --atlas-color-danger-contrast-rgb: 255, 255, 255;
  --atlas-color-danger-shade: #b41212;
  --atlas-color-danger-tint: #e32222;
  --atlas-color-dander-light: #FBEDED;

  /** dark **/
  --atlas-color-dark: #222428;
  --atlas-color-dark-rgb: 34, 36, 40;
  --atlas-color-dark-contrast: #ffffff;
  --atlas-color-dark-contrast-rgb: 255, 255, 255;
  --atlas-color-dark-shade: #1e2023;
  --atlas-color-dark-tint: #383a3e;

  /** medium **/
  --atlas-color-medium: #92949c;
  --atlas-color-medium-rgb: 146, 148, 156;
  --atlas-color-medium-contrast: #ffffff;
  --atlas-color-medium-contrast-rgb: 255, 255, 255;
  --atlas-color-medium-shade: #808289;
  --atlas-color-medium-tint: #9d9fa6;

  /** light **/
  --atlas-color-light: #f4f5f8;
  --atlas-color-light-rgb: 244, 245, 248;
  --atlas-color-light-contrast: #1A202C;
  --atlas-color-light-contrast-rgb: 0, 0, 0;
  --atlas-color-light-shade: #d7d8da;
  --atlas-color-light-tint: #f5f6f9;


  --alt-background: #f8fafc;
  --atlas-color-background:#E2E8F0;
  --atlas-color-step-600: #1A202C;
  --atlas-text-color: #1A202C;
  --atlas-primary-disabled: #FFE3B6;

  --atlas-color-yellow: #F2C94C;
  --atlas-color-green: #34C759;
  --atlas-color-red: #FF3B30;
  --atlas-color-blue: #007AFF;
  --atlas-color-purple: #AF52DE;

  --atlas-light-grey-background: #F7FAFC;

  --atlas-secondary-color-step-100: #F7FAFC;
  --atlas-secondary-color-step-200: #EDF2F7;
  --atlas-secondary-color-step-300: #E2E8F0;
  --atlas-secondary-color-step-400: #CBD5E0;
    --atlas-secondary-color-step-500: #A0AEC0;
  --atlas-secondary-color-step-600: #718096;
  --atlas-secondary-color-step-700: #4A5568;
  --atlas-secondary-color-step-800: #2D3748FF;
}
