.map-marker {
  width: 36px;
  height: 82px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &.reached {
    width: 57px;
    height: 87px;
  }
}

@mixin map-marker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

@mixin marker-body {
  margin-bottom: 2px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin marker-pin {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.map-obelisk-marker-container {
  @include map-marker-container;
  pointer-events: none !important;
  --main-color: var(--atlas-color-primary-tint);

  &.approved {
    --main-color: var(--atlas-color-success);
  }

  .main-body {
    @include marker-body;
    margin-bottom: 4px;
    border-radius: 8px;
    border: 2px solid var(--main-color);
    cursor: pointer;
    pointer-events: auto;

    img {
      max-width: unset;
      pointer-events: auto;
    }
  }

  .label {
    position: absolute;
    top: 10px;
    right: calc(100% + 8px);
    text-wrap: nowrap;
    font-size: 12px;
    font-weight: 700;
    //letter-spacing: 0.1rem;
    color: var(--ion-text-color);
    text-shadow:
      -0.5px -0.5px 0 var(--atlas-color-light),
      0.5px -0.5px 0 var(--atlas-color-light),
      -0.5px  0.5px 0 var(--atlas-color-light),
      0.5px  0.5px 0 var(--atlas-color-light);
  }

  &.small {
    width: 28px;
    //-3px (half og the pin size)
    height: 73px;

    .main-body {
      width: 28px;
      height: 28px;
    }
  }

  &.medium {
    width: 36px;
    height: 89px;

    .main-body {
      width: 36px;
      height: 36px;
    }
  }

  &.big {
    width: 42px;
    height: 101px;

    .main-body {
      width: 42px;
      height: 42px;
    }
  }

  &.visited {
    --main-color: var(--atlas-color-success);

    .main-body {
      box-shadow: 2px 2px 10px 0 rgba(0, 186, 136, 0.60);
    }
  }

  .pin {
    @include marker-pin;
    background: var(--main-color);
  }
}

.map-quest-marker-container {
  width: 50px;
  height: 113px;
  @include map-marker-container;

  &.easy {
    --b-color: var(--atlas-color-green);
  }

  &.medium {
    --b-color: var(--atlas-color-primary);
  }

  &.hard {
    --b-color: var(--atlas-color-red);
  }

  &.master {
    --b-color: var(--atlas-color-purple);
  }

  .main-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: white;
    border: 3px solid var(--b-color);
    color: var(--b-color);
    margin-bottom: 2px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img, ion-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.daily {
    width: 40px;
    height: 93px;

    .main-circle {
      width: 40px;
      height: 40px;
      font-size: 20px;
      background: white;
      border: 3px solid var(--b-color);
      color: var(--b-color);
    }
  }

  .organization {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;

    .inner-img {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .pin {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--b-color);
  }
}

.cluster-marker {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--atlas-color-yellow);
  border: 3px solid var(--atlas-secondary-color-step-800);
  color: var(--atlas-secondary-color-step-800);

  cursor: pointer;

  .count {
    font-size: 20px;
    font-weight: 700;
    font-family: 'Noto Sans', sans-serif;
  }
}

.marker-number {
  position: absolute;
  z-index: 1;
  top: 7px;
  left: 13px;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Noto Sans', sans-serif;

  &.two-digits {
    left: 7px;
  }
}

.exclamation-mark {
  position: absolute;
  z-index: 1;
  top: 7px;
  left: 15px;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Noto Sans', sans-serif;
}

.map-button {
  position: absolute;
  z-index: 3;
  right: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  --size: 40px;
  width: var(--size);
  height: var(--size);
  font-size: 20px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  cursor: pointer;

  &.stretch {
    --size: 44px;
    width: max-content;
  }
}

//.on-map-comment-container {
//  display: flex;
//  flex-direction: column;
//  align-items: center;
//
//  .comment-body {
//    max-width: 200px;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    background: rgba(255, 255, 255);
//    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
//    border: 1px solid var(--atlas-color-dark);
//    border-radius: 12px;
//    padding: 8px;
//    cursor: pointer;
//  }
//
//  .triangle-down {
//    width: 0;
//    height: 0;
//    border-left: 5px solid transparent;
//    border-right: 5px solid transparent;
//    border-top: 10px solid #ffffff; /* Change the color as needed */
//  }
//}

.comment-on-map-content {
  display: flex;
  padding: 4px;

  flex-direction: column;
  cursor: pointer;

  img {
    width: 212px;
    height: unset;
    border-radius: 4px;
  }

  &.unfocused {
    flex-direction: row;
    gap: 4px;

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
  }
}

@keyframes jump {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-35px);
  }
}

.user-placed-data-marker {
  width: 44px;
  height: 132px;
  @include map-marker-container;

  &.height-92 {
    height: 102px;
  }

  .marker-body-container {
    width: 44px;
    height: 44px;
    border: 2px solid var(--atlas-color-blue);
    background: var(--atlas-color-blue);
    border-radius: 4px;
    color: white;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
  }

  .round-body {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: transparent;
    border: 4px solid var(--atlas-color-blue);
  }

  .pin {
    width: 3px;
    height: 24px;
    background: var(--atlas-color-blue);
    border-radius: 0 0 2px 2px;
  }

  .bearing-arrow {
    position: absolute;
    top: 62px;
    //left: 50%;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 18px solid var(--atlas-color-red);
    border-radius: 2px;
    //transform: translateX(-50%);
  }

  .jump-animation {
    animation: jump 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}
