@mixin q-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 12px !important;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

@mixin q-large-button {
  height: 56px;
  width: 100%;
  font-size: 20px;
}

@mixin q-regular-button {
  height: 48px;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
}

@mixin q-small-button {
  height: 32px;
  font-size: 12px;
  line-height: 16px;
  padding: 0 16px;
}

@mixin q-bald-button {
  font-weight: 600;
}

@mixin q-primary-button {
  background: var(--atlas-color-primary);
  color: white;

  &:disabled {
    color: #fff !important;
    background-color: var(--atlas-primary-disabled) !important;
  }
}

@mixin q-warning-button {
  background: var(--atlas-color-red);
  color: white;

  &:disabled {
    background: #f6b1ad !important;
  }
}

@mixin q-secondary-button {
  background: transparent;
  color: var(--atlas-text-color);
  border: 1px solid var(--atlas-text-color);

  &:disabled {
    border-color: var(--atlas-secondary-color-step-400)  !important;
    color: var(--atlas-secondary-color-step-400) !important;
    background: transparent;
  }
}


@mixin mat-table-overrides {
  width: 100%;
  .name-col-container {
    display: flex;
    align-items: center;
    font-weight: 600;
  }
  .menu-table-data {
    text-align: end;
  }
  ::ng-deep {
    .mat-mdc-cell {
      font-weight: 400;
      font-size: 14px;
      border: 0;
    }
    .mat-mdc-row {
      padding: 8px;
      cursor: pointer;
      &:hover {
        background: var(--atlas-light-grey-background);
      }
    }
    .mat-mdc-header-cell {
      font-weight: 600 !important;
      border: 0;
      color: var(--atlas-secondary-color-step-600);
      font-size: 14px;
    }
    .mat-mdc-header-row {
      height: 40px;
    }
  }
}
