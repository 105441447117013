@use "./mixins" as mixins;

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  --toolbar-height: 0px;
}

html,
body {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  padding: var(--toolbar-height) 0 0;
  font-family: 'Noto Sans', sans-serif;
}
button {
  font-family: 'Noto Sans', sans-serif;
}


.full-width {
  width: 100%;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-justify-between {
  display: flex;
  justify-content: space-between;
}

.q-primary {
  color: var(--atlas-color-primary)
}

.q-yellow {
  color: var(--atlas-color-yellow)
}

.q-blue {
  color: var(--atlas-color-blue)
}

.q-green {
  color: var(--atlas-color-green)
}

.q-red {
  color: var(--atlas-color-red)
}

.q-purple {
  color: var(--atlas-color-purple)
}

.secondary-text-color {
  color: var(--atlas-secondary-color-step-600)
}

.secondary-text-color-soft {
  color: var(--atlas-secondary-color-step-500)
}

.horizontal-divider {
  width: 100%;
  height: 1px;
  background-color: var(--atlas-secondary-color-step-300);
}


.hidden-on-phones {
  @media (max-width: 600px) {
    display: none;
  }
}

.shown-on-phones {
  @media (min-width: 601px) {
    display: none;
  }
}

.success-snackbar {
  --mdc-snackbar-container-color: var(--atlas-color-success) !important;
  --mat-snack-bar-button-color: #bdffec;
  --mdc-snackbar-supporting-text-color: #fff;
}

.error-snackbar {
  --mdc-snackbar-container-color: var(--atlas-color-danger) !important;
  --mat-snack-bar-button-color: #e0acac;
  --mdc-snackbar-supporting-text-color: #fff;
}


.color-selector-button {
  width: 32px;
  height: 32px;
  border-radius: 12px;
  border: 2px solid var(--atlas-secondary-color-step-800);

  cursor: pointer;
}

.text-centered {
  text-align: center;
}

.primary-btn {
  @include mixins.q-button;
  @include mixins.q-regular-button;
  @include mixins.q-primary-button;
}

.warning-btn {
  @include mixins.q-button;
  @include mixins.q-regular-button;
  @include mixins.q-warning-button;
}

.secondary-btn {
  @include mixins.q-button;
  @include mixins.q-regular-button;
  @include mixins.q-secondary-button;
}

.primary-bar-btn {
  @include mixins.q-button;
  @include mixins.q-small-button;
  @include mixins.q-bald-button;
  @include mixins.q-primary-button;
}

.secondary-bar-btn {
  @include mixins.q-button;
  @include mixins.q-small-button;
  @include mixins.q-bald-button;
  @include mixins.q-secondary-button;

  &.active {
    border-color: var(--atlas-color-primary);
    color: var(--atlas-color-primary);
  }
}

.warning-bar-btn {
  @include mixins.q-button;
  @include mixins.q-small-button;
  @include mixins.q-bald-button;
  @include mixins.q-warning-button;
}

.image-btn {
  @include mixins.q-button;
  @include mixins.q-small-button;
  width: 32px;
  @include mixins.q-bald-button;
  @include mixins.q-secondary-button;

  &.active, &.highlighted {
    border: 2px solid var(--atlas-color-primary);
    color: var(--atlas-color-primary);
  }

  &.borderless {
    border: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}
