/* You can add global styles to this file, and also import other style files */
@use '../dist/quest-atlas-angular-components/styles/mixins' as mixins;
@use "../dist/quest-atlas-angular-components/styles/common-styles" as *;
@use "../dist/quest-atlas-angular-components/styles/containers-and-dimensions" as *;
@use "../dist/quest-atlas-angular-components/styles/global-map-styles" as *;
@import '../dist/quest-atlas-angular-components/styles/variables';


html {
  --toolbar-height: 48px;
  .mat-toolbar-single-row {
    --mat-toolbar-standard-height: 48px;
    background: white;
  }
}


h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.separator {
  width: 100%;
  height: 1px;
  flex: 1 1 0;
  background-color: var(--atlas-color-secondary);

  &.light {
    background-color: var(--atlas-secondary-color-step-300);
  }
}

.toolbar {
  height: var(--toolbar-height);
  padding: 8px 16px;
  position: fixed;
  justify-content: space-between;
  background-color: white;
  left: 0;
  gap: 16px;

  &.top {
    top: 0;
  }

  &.bottom {
    bottom: 0;
  }

  &.content-centered {
    justify-content: center;
  }

  &.content-end {
    justify-content: flex-end;
  }
}

.color-green {
  color: var(--atlas-color-green);
}

.color-red {
  color: var(--atlas-color-red);
}

.color-yellow {
  color: var(--atlas-color-yellow);
}

.color-soft-grey {
  color: var(--atlas-secondary-color-step-600);
}

.color-grey-700 {
  color: var(--atlas-secondary-color-step-700);
}

.primary-btn {
  @include mixins.q-button;
  @include mixins.q-regular-button;
  @include mixins.q-primary-button;
}

.warning-btn {
  @include mixins.q-button;
  @include mixins.q-regular-button;
  @include mixins.q-warning-button;
}

.secondary-btn {
  @include mixins.q-button;
  @include mixins.q-regular-button;
  @include mixins.q-secondary-button;
}

.primary-bar-btn {
  @include mixins.q-button;
  @include mixins.q-small-button;
  @include mixins.q-bald-button;
  @include mixins.q-primary-button;
}

.secondary-bar-btn {
  @include mixins.q-button;
  @include mixins.q-small-button;
  @include mixins.q-bald-button;
  @include mixins.q-secondary-button;

  &.active {
    border-color: var(--atlas-color-primary);
    color: var(--atlas-color-primary);
  }
}

.warning-bar-btn {
  @include mixins.q-button;
  @include mixins.q-small-button;
  @include mixins.q-bald-button;
  @include mixins.q-warning-button;
}

.image-btn {
  @include mixins.q-button;
  @include mixins.q-small-button;
  width: 32px;
  @include mixins.q-bald-button;
  @include mixins.q-secondary-button;

  &.active, &.highlighted {
    border: 2px solid var(--atlas-color-primary);
    color: var(--atlas-color-primary);
  }

  &.borderless {
    border: none;
  }
}

.general-range {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  background-color: var(--atlas-secondary-color-step-300);
  outline: none;
  border-radius: 100px;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 17px;
    height: 17px;
    border: 2px solid var(--atlas-color-blue);
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 17px;
    height: 17px;
    border: 2px solid var(--atlas-color-blue);
    border-radius: 50%;
    background: white;
    cursor: pointer;
  }

  &::-moz-range-progress {
    background-color: var(--atlas-color-blue);
  }
}

.element-shadow {
  box-shadow: 0 2px 20px rgba(203, 213, 224, 0.5);
}

.main-heading-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  font-family: 'Noto Sans', sans-serif;

  &.text-18 {
    font-size: 18px;
  }
}

.pointer {
  cursor: pointer;
}

.inline {
  display: inline-block;
}

.usual-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.regular-img-size {
  width: 24px;
  height: 24px;
  margin: 0 2px;
}

.smaller-img-size {
  width: 18px;
  height: 18px;
  margin: 0 2px;
}

.margin-top-24 {
  margin-top: 24px;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.margin-top-18 {
  margin-top: 18px;
}

.margin-top-4 {
  margin-top: 4px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-12 {
  margin-top: 12px;
}

.margin-bottom-4 {
  margin-bottom: 4px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-12 {
  margin-bottom: 12px;
}

.margin-bottom-16 {
  margin-bottom: 18px;
}

.margin-bottom-18 {
  margin-bottom: 18px;
}

.margin-left-12 {
  margin-left: 12px;
}

.margin-right-12 {
  margin-right: 12px;
}

.margin-left-4 {
  margin-left: 4px;
}

.margin-right-4 {
  margin-right: 4px;
}

.general-text {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--color);
}

.general-big-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: var(--color);
}

.link-text {
  font-weight: 500;
  color: var(--atlas-color-primary);
  text-decoration: none;
  cursor: pointer;

  &.disabled {
    color: var(--atlas-color-yellow);
    cursor: default;
  }
}

.field-error-info {
  color: var(--atlas-color-danger);
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
}

.text-color-regular {
  color: var(--atlas-text-color);
}

.text-color-soft {
  color: var(--atlas-secondary-color-step-600);
}

.secondary-text {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--atlas-secondary-color-step-600);
}

.small-secondary-text {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: var(--atlas-secondary-color-step-600);
}

.img-thumbnail {
  display: inline-block;
  position: relative;
  width: 104px;
  height: 59px;
  border-radius: 4px;
  margin: 0 4px;
  overflow: hidden;
  background-size: 100%;

  .icon-container {
    position: absolute;
    top: -2px;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    background-color: white;

    &.close {
      right: -2px;
    }

    &.edit {
      padding: 2px;
      left: -2px;
    }
  }

  .small-icon {
    width: 16px;
    height: 16px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.display-flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

//map styles

.mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: default;
}

.find-marker {
  width: 57px;
  height: 74px;
  display: flex;
  align-items: start;
  justify-content: center;
}

.vertex-controls-modal {
  position: absolute;
  top: -56px;
  left: -14px;

  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 44px;
  height: 44px;

  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 12px;

  &.wider {
    min-width: 88px;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.rotated-icon {
  transform: rotate(180deg);
}


// Move this to a separate file
.regular-12 {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.semibold-12 {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

.bold-12 {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}


.regular-14 {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.semibold-14 {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.bold-14 {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}


.regular-16 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.semibold-16 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.bold-16 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}


.regular-18 {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.semibold-18 {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.bold-18 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}


.regular-20 {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.semibold-20 {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}

.bold-20 {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}


.regular-24 {
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
}

.semibold-24 {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
}

.bold-24 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}
